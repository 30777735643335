const formatFullStoryKeys = obj => {
  const formattedEntries = Object.entries(obj).filter(val => val[1] !== undefined && val[1] !== null).map(([key, val]) => {
    let value = val;
    if (typeof val === 'boolean') {
      return [`${key}_bool`, value];
    }
    if (typeof val === 'number') try {
      value = typeof val === 'string' ? val : JSON.stringify(val);
    } catch {
      value = '';
    }
    return [`${key}_str`, val];
  });
  return Object.fromEntries(formattedEntries);
};

export { formatFullStoryKeys };
