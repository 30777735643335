import { createContext, useContext, useState, useEffect } from 'react';
import type { Analytics } from '@customerio/cdp-analytics-browser';
import { useUser } from '../users/useUser';

const writeKey = process.env.NEXT_PUBLIC_CUSTOMERIO_ANALYTICS_WRITE_KEY || '';
const siteId = process.env.NEXT_PUBLIC_CUSTOMERIO_ANALYTICS_SITE_ID || '';

const AnalyticsContext = createContext<Analytics | null>(null);

export const CustomerioAnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const [analytics, setAnalytics] = useState<Analytics | null>(null);
  const { user } = useUser();

  useEffect(() => {
    const initializeAnalytics = async () => {
      const AnalyticsBrowser = (await import('@customerio/cdp-analytics-browser')).AnalyticsBrowser;
      const [instance] = await AnalyticsBrowser?.load(
        { writeKey },
        {
          integrations: {
            'Customer.io In-App Plugin': { siteId },
          },
        }
      );
      setAnalytics(instance);
    };

    initializeAnalytics();
  }, []);

  const analyticsUserId = analytics?.user()?.id();
  // Handle users who are already logged in to consumer when the customer.io integration
  // was added to the project.
  // If the user is logged in but the customer.io user id is different, we update the customer.io user id.
  useEffect(() => {
    if (user?.userId && user?.userId !== analyticsUserId) {
      analytics?.identify(`${user.userId}`, {
        email: user.email,
      });
    }
  }, [user, analyticsUserId, analytics]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export const useCustomerioAnalytics = () => {
  const result = useContext(AnalyticsContext);

  return result;
};
