function groupRegionByState(regions) {
  return regions.reduce((acc, curr) => {
    if (curr.state && acc[curr.state]) {
      acc[curr.state].push(curr);
    } else if (curr.state) {
      acc[curr.state] = [curr];
    }
    return acc;
  }, {});
}
function groupActiveRegionByState(regions) {
  return regions.reduce((acc, curr) => {
    if (curr.status === 'Active') {
      if (curr.state && acc[curr.state]) {
        acc[curr.state].push(curr);
      } else if (curr.state) {
        acc[curr.state] = [curr];
      }
    }
    return acc;
  }, {});
}

export { groupActiveRegionByState, groupRegionByState };
